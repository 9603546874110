/**

*/
import PropTypes from "prop-types";
import Link from "@mui/material/Link";
import { Typography, Box } from "@mui/material";

function Footer({ company, links }) {
  const renderLinks = () =>
    links.map((link) => (
      <Box key={link.name} component="li" px={2} lineHeight={1}>
        <Link href={link.href} target="_blank">
          <Typography variant="button" fontWeight="regular" color="text">
            {link.name}
          </Typography>
        </Link>
      </Box>
    ));

  return (
    <Box
      width="100%"
      display="flex"
      flexDirection={{ xs: "column", lg: "row" }}
      justifyContent="space-between"
      alignItems="center"
      px={1.5}
    >
      <Box
        component="ul"
        sx={({ breakpoints }) => ({
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "center",
          listStyle: "none",
          mt: 3,
          mb: 0,
          p: 0,

          [breakpoints.up("lg")]: {
            mt: 0,
          },
        })}
      >
        {renderLinks()}
      </Box>
    </Box>
  );
}

Footer.defaultProps = {
  company: { href: "https://xinglin-group.com/", name: "上海擎算科技" },
  links: [
    { href: "https://xinglin-group.com/", name: "上海擎算科技" },
    { href: "https://xinglin-group.com/", name: "About Us" },
    { href: "https://xinglin-group.com/", name: "Blog" },
    { href: "https://xinglin-group.com/", name: "License" },
  ],
};

Footer.propTypes = {
  company: PropTypes.objectOf(PropTypes.string),
  links: PropTypes.arrayOf(PropTypes.object),
};

export default Footer;
