import React, { useState, useEffect } from "react";
import axios from "axios";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Backdrop from "@mui/material/Backdrop";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  List,
  ListItem,
  ListItemText,
  Radio,
  Box,
  Button,
  IconButton,
  Typography,
  CircularProgress,
  InputAdornment,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import brandWhite from "assets/images/apple-icon.png";

const SelectModelDialog = ({ open, onClose, onConfirm }) => {
  const [activeTab, setActiveTab] = useState("modelFunctions");
  const [searchQuery, setSearchQuery] = useState("");
  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };
  const handleSearch = (event) => {
    if (event.key === "Enter" || event.type === "click") {
      console.log("Search Query:", searchQuery);
      // 执行搜索操作
    }
  };
  const modelSet = [];
  /*
  const modelFunction = [
    {
      value: "ALL",
      text: "全部模型",
    },
    {
      value: "TEXT_TO_TEXT",
      text: "文字生成文字",
    },
    {
      value: "TEXT_TO_SPEECH",
      text: "文字生成语音",
    },
    {
      value: "SPEECH_TO_TEXT",
      text: "语音生成文字",
    },
    {
      value: "TEXT_TO_IMAGE",
      text: "文字生成图片",
    },
    {
      value: "IMAGE_TO_IMAGE",
      text: "图片生成图片",
    },
  ];
  */
  const modelFunction = [
    {
      value: "TEXT_TO_TEXT",
      text: "文字生成文字",
    },
  ];

  const [models, setModels] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [infoLabel, setInfoLabel] = useState("");
  const [infoText, setInfoText] = useState("");
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const handleClose = () => setError(false);

  const [filter, setFilter] = useState("TEXT_TO_TEXT");
  const handleFilterChange = (f) => {
    setFilter(f);
    getModels(f);
  };

  const getModels = async (f) => {
    setLoading(true);
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      let url;
      if (!f || f === "ALL") {
        url = `${apiUrl}/model/findAll`;
      } else {
        url = `${apiUrl}/model/findByType?model_type=${f}`;
      }
      let response = await axios.get(url, {
        headers: { "Content-Type": "application/json" },
      });
      if (response.status === 200) {
        setModels(response.data);
      } else {
        setInfoLabel("模型加载");
        setInfoText("获取模型列表出错");
        setError(true);
      }
    } catch (error) {
      console.log(error);
      setInfoLabel("模型加载");
      setInfoText("获取模型列表出错");
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getModels(filter);
  }, []);

  const [selectedModel, setSelectedModel] = useState(null);
  const handleSelectModel = (model) => {
    setSelectedModel(model);
  };

  const handleConfirm = () => {
    if (selectedModel.model_type !== "TEXT_TO_TEXT") {
      setInfoLabel("模型选择");
      setInfoText("当前只支持选择文字生产文字类型的模型，请重新选择");
      setError(true);
      return;
    }
    onConfirm(selectedModel);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">选择模型</Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ display: "flex", height: "50vh", padding: 0 }}>
        <Box
          sx={{
            flex: 1,
            borderRight: "1px solid #ddd",
            display: "flex",
            flexDirection: "column",
            padding: "1rem",
          }}
        >
          <Box sx={{ position: "relative", marginBottom: "1rem" }}>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="从全部模型里搜索"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              onKeyDown={handleSearch}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleSearch}>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-around",
              borderBottom: "1px solid #e0e0e0",
            }}
          >
            <Button
              onClick={() => handleTabChange("modelSeries")}
              sx={{
                color: activeTab === "modelSeries" ? "#000000" : "#636363",
                flex: 1,
                borderRadius: 0,
                borderBottom: activeTab === "modelSeries" ? "2px solid #000" : "none",
              }}
            >
              模型系列
            </Button>
            <Button
              onClick={() => handleTabChange("modelFunctions")}
              sx={{
                color: activeTab === "modelFunctions" ? "#000000" : "#636363",
                flex: 1,
                borderRadius: 0,
                borderBottom: activeTab === "modelFunctions" ? "2px solid #000" : "none",
              }}
            >
              模型功能
            </Button>
          </Box>
          <Box sx={{ overflowY: "auto", flexGrow: 1, p: 2 }}>
            <List>
              {activeTab === "modelSeries"
                ? modelSet.map((item, index) => (
                    <ListItem key={index}>
                      <Typography>{item.text}</Typography>
                    </ListItem>
                  ))
                : modelFunction.map((item, index) => (
                    <ListItem key={index}>
                      <Button
                        onClick={() => handleFilterChange(item.value)}
                        sx={{
                          color: filter === item.value ? "#650bdb" : "#000000",
                          fontSize: "1rem",
                          fontWeight: "regular",
                        }}
                      >
                        {item.text}
                      </Button>
                    </ListItem>
                  ))}
            </List>
          </Box>
        </Box>
        <Box
          sx={{
            flex: 2,
            padding: "1rem",
            display: "flex",
            flexDirection: "column",
            overflowY: "auto",
          }}
        >
          {loading && <CircularProgress />}
          <List>
            {models.map((object, index) => (
              <ListItem
                key={object.id}
                onClick={() => handleSelectModel(object)}
                sx={{
                  cursor: "pointer",
                  borderRadius: 2,
                  border: "2px solid",
                  borderColor:
                    selectedModel && selectedModel.id === object.id ? "#650bdb" : "#a8a8a8",
                  bgcolor: "#ffffff",
                  "&:hover": {
                    bgcolor: "#ececec",
                  },
                  mb: "1rem",
                }}
              >
                <Box
                  component="img"
                  src={brandWhite}
                  sx={{ width: "24px", height: "24px", mr: "1rem", ml: "1rem" }}
                />
                <ListItemText primary={object.name} />
                <Radio
                  checked={selectedModel?.id === object.id}
                  sx={{
                    color: "#a8a8a8",
                    "&.Mui-checked": {
                      color: "#650bdb",
                    },
                  }}
                />
              </ListItem>
            ))}
          </List>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box sx={{ flex: 1 }}>
          <Typography>已选：{selectedModel ? selectedModel.name : "未选择模型"}</Typography>
        </Box>
        <Button
          onClick={onClose}
          variant="outlined"
          sx={{
            bgcolor: "#ffffff",
            color: "#000000",
            fontSize: 14,
            fontWeight: "light",
            borderColor: "#797979",
          }}
        >
          取消
        </Button>
        <Button
          onClick={handleConfirm}
          variant="contained"
          disabled={!selectedModel}
          sx={{
            bgcolor: "#650bdb",
            color: "#ffffff",
            fontSize: 14,
            fontWeight: "light",
          }}
        >
          确认
        </Button>
      </DialogActions>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={error}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={error}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6">
              {infoLabel}
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
              {infoText}
            </Typography>
          </Box>
        </Fade>
      </Modal>
    </Dialog>
  );
};

SelectModelDialog.defaultProps = {
  open: false,
  onClose: () => {},
  onConfirm: () => {},
};

SelectModelDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default SelectModelDialog;
