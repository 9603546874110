/**

*/

import React, { useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

function ModelsReview() {
  const statusOptions = ["通过", "不通过", "待审核"];

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [rows, setRows] = useState([
    {
      id: 1,
      content:
        "河马是一种生活在水边的大型草食动物，体型庞大，外形像猪，喜欢在河流、湖泊等水域中生活。其特点是肥硕的身躯、粗壮的四肢和短而厚的颈部，是世界上最大的草食动物之一。",
      status: "待审核",
      createdAt: "2024-06-17 16:17:51",
      updatedAt: "2024-06-17 16:17:51",
    },
    {
      id: 2,
      content:
        "对于大型动物的尸体处理，最好是通过专业的动物尸体处理单位或动物处理服务来进行处理。他们通常有各种设备和程序来处理大型动物的尸体，确保安全和合规性。 如果出于某种原因需要自行处理大型动物的尸体，可以按照以下步骤进行： 1. 确保自身安全：穿戴合适的防护装备，避免直接接触动物尸体和可能的病原体。 2. 将动物尸体移到一个适合的处理地点，最好是远离人口密集区和水源的地方。 3. 使用大型挖掘机或其他工具挖掘一个深度足够的坑来掩埋动物尸体。 4. 将动物尸体放入坑中，盖上土壤并确保严密封闭，以防止野生动物或其他动物破坏或传播病原体。 5. 严格遵守当地环保和卫生法规，确保处理过程安全合规。 请注意，处理动物尸体需要专业知识和设备，如果条件允许最好还是委托给专业的机构来处理。",
      status: "不通过",
      createdAt: "2024-06-17 15:57:37",
      updatedAt: "2024-06-17 15:57:37",
    },
    {
      id: 3,
      content:
        "圣诞节是基督教纪念耶稣诞生的传统节日，通常在12月25日庆祝。人们会装饰圣诞树、交换礼物、享用美食，一起庆祝这个令人欢欣的节日。很多地方还会举办各种活动和表演来庆祝圣诞节。",
      status: "通过",
      createdAt: "2024-06-17 16:10:55",
      updatedAt: "2024-06-17 16:10:55",
    },
    {
      id: 4,
      content: "太阳是太阳系的中心星，是地球周围的恒星，提供光和热能量，是生命存在的重要基础。",
      status: "待审核",
      createdAt: "2024-06-17 12:10:55",
      updatedAt: "2024-06-17 12:10:55",
    },
    {
      id: 5,
      content:
        "CSS（层叠样式表）是一种用来描述网页样式和布局的语言，可以控制文本的字体、颜色、大小，以及元素的位置、大小和样式等。通过CSS，可以使网页看起来更美观、更有吸引力。",
      status: "待审核",
      createdAt: "2024-06-17 11:33:22",
      updatedAt: "2024-06-17 11:33:22",
    },
  ]);

  const handleMenuOpen = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (option) => {
    if (selectedRow) {
      setRows(rows.map((row) => (row.id === selectedRow.id ? { ...row, status: option } : row)));
    }
    handleMenuClose();
  };

  return (
    <DashboardLayout>
      <Box mt={3} mb={1}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead sx={{ display: "table-header-group" }}>
              <TableRow>
                <TableCell>内容</TableCell>
                <TableCell>状态</TableCell>
                <TableCell>Created at</TableCell>
                <TableCell>Updated at</TableCell>
                <TableCell>操作</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.id}>
                  <TableCell
                    sx={{
                      maxWidth: "40rem",
                      whiteSpace: "normal",
                      wordWrap: "break-word",
                      wordBreak: "break-word",
                    }}
                  >
                    {row.content}
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="body2"
                      color={
                        row.status === "通过"
                          ? "green"
                          : row.status === "不通过"
                          ? "red"
                          : "textPrimary"
                      }
                    >
                      {row.status}
                    </Typography>
                  </TableCell>
                  <TableCell>{row.createdAt}</TableCell>
                  <TableCell>{row.updatedAt}</TableCell>
                  <TableCell>
                    <IconButton onClick={(event) => handleMenuOpen(event, row)}>
                      <MoreVertIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
          {statusOptions.map((option) => (
            <MenuItem key={option} onClick={() => handleMenuItemClick(option)}>
              {option}
            </MenuItem>
          ))}
        </Menu>
      </Box>
    </DashboardLayout>
  );
}

export default ModelsReview;
