import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Box,
  Button,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CodeBlock from "./CodeBlock";
import PropTypes from "prop-types";

const CodeSample = ({ open, onClose, model, onAPI, onDetail }) => {
  if (!open || !model) {
    return null;
  }
  const [language, setLanguage] = useState("python");
  let template = `
def fibonacci(n):
  """Return the n-th Fibonacci number."""
  if n <= 0:
    return 0
  elif n == 1:
    return 1
  else:
    return fibonacci(n-1) + fibonacci(n-2)

def print_fibonacci_sequence(count):
  """Print a Fibonacci sequence of a given count."""
  sequence = [fibonacci(i) for i in range(count)]
  print(f"The first {count} numbers in the Fibonacci sequence are: {sequence}")

if __name__ == "__main__":
  print_fibonacci_sequence(10)
  `;
  const [codeContent, setCodeContent] = useState(template);

  const handleOpenDetail = () => {
    onClose();
    onDetail(model);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle bgcolor="#f3f3f3">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6" ml={2}>
            {model.name}
          </Typography>
          <IconButton edge="end" color="inherit" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Button
            variant="outlined"
            sx={{
              color: "#8A2BE2",
              borderColor: "#8A2BE2",
              bgcolor: "#d0c5da",
              "&:hover": {
                color: "#808080",
              },
              fontSize: 15,
              fontWeight: "regular",
              ml: 2,
            }}
          >
            {language}
          </Button>
          <Box>
            <Button
              variant="text"
              sx={{
                color: "#808080",
                "&:hover": {
                  color: "#474747",
                },
                fontSize: 15,
                fontWeight: "regular",
              }}
              onClick={handleOpenDetail}
            >
              模型详情
            </Button>
            <Button
              variant="text"
              sx={{
                color: "#808080",
                "&:hover": {
                  color: "#474747",
                },
                fontSize: 15,
                fontWeight: "regular",
              }}
              onClick={onAPI}
            >
              我的APIKEY
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            backgroundColor: "#2d2d2d",
            color: "#ffffff",
            borderRadius: "8px",
            padding: "16px",
            fontFamily: "monospace",
            fontSize: "0.875rem",
          }}
        >
          <CodeBlock language={language} code={codeContent} />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

CodeSample.defaultProps = {
  open: false,
  onClose: () => {},
  model: {},
  onAPI: () => {},
  onDetail: () => {},
};

CodeSample.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  model: PropTypes.object,
  onAPI: PropTypes.func.isRequired,
  onDetail: PropTypes.func.isRequired,
};

export default CodeSample;
