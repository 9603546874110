import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ConfirmDialog from "examples/ConfirmDialog";
import CreateApiKeyDialog from "./CreateAPIDialog";

const ApiKeyDialog = ({ open, onClose }) => {
  const [data, setData] = useState([]);
  const tempData = [
    {
      api: "sdwqlkdjskladjsaldjsalkdsakldsalkd",
      showApi: false,
      app: "AI狼人杀",
      info: "正在test",
      time: "2024-07-11 10:30",
    },
    {
      api: "sadfdsafcbvxzbvcbvcxbvcxbxcvb",
      showApi: false,
      app: "Store",
      info: "尚未test",
      time: "2024-07-11 10:30",
    },
    {
      api: "43253425432reyeryherwherwh",
      showApi: false,
      app: "Store",
      info: "尚未test",
      time: "2024-07-11 10:30",
    },
    {
      api: "gfdnfgdnfgdnfgdn3243214321",
      showApi: false,
      app: "AI狼人杀",
      info: "尚未test",
      time: "2024-07-11 10:30",
    },
    {
      api: "fdsbfdsbfdsb34252315213fdg",
      showApi: false,
      app: "Maas",
      info: "正在test",
      time: "2024-07-11 10:30",
    },
  ];
  const loadData = () => {
    setData(tempData);
  };
  const showApi = (index) => {
    setData((prevData) => {
      const newData = [...prevData];
      newData[index] = {
        ...newData[index],
        showApi: !newData[index].showApi,
      };
      return newData;
    });
  };

  const encryptString = (s) => {
    const start = s.slice(0, 4);
    const end = s.slice(-4);
    const maskedString = start + "*".repeat(s.length - 8) + end;
    return maskedString;
  };

  const [create, setCreate] = useState(false);
  const createApi = (apiName, apiInfo) => {
    let d = new Date();
    let n = d.toLocaleString();
    let newAPi = {
      api: "newCreatedAPi21332131",
      showApi: false,
      app: apiName,
      info: apiInfo,
      time: n,
    };
    setData((prevData) => [...prevData, newAPi]);
    setCreate(false);
  };
  const handleOpenCreate = () => {
    setCreate(true);
  };
  const handleCloseCreate = () => {
    setCreate(false);
  };

  const [selectedApi, setSelectedApi] = useState(0);
  const [confirm, setConfirm] = useState(false);
  const deleteApi = () => {
    setData((prevData) => prevData.filter((_, i) => i !== selectedApi));
    setConfirm(false);
  };
  const handleOpenConfirm = (index) => {
    setSelectedApi(index);
    setConfirm(true);
  };
  const handleCloseConfirm = () => {
    setConfirm(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
      <DialogTitle>
        我的API-KEY
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#650bdb",
            color: "#ffffff",
            float: "right",
            mb: 2,
          }}
          onClick={handleOpenCreate}
        >
          创建API KEY
        </Button>
        <TableContainer>
          <Table stickyHeader>
            <TableHead sx={{ display: "table-header-group" }}>
              <TableRow>
                <TableCell>API KEY</TableCell>
                <TableCell>归属应用</TableCell>
                <TableCell>描述</TableCell>
                <TableCell>创建时间</TableCell>
                <TableCell>操作</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>{item.showApi ? item.api : encryptString(item.api)}</TableCell>
                  <TableCell>{item.app}</TableCell>
                  <TableCell>{item.info}</TableCell>
                  <TableCell>{item.time}</TableCell>
                  <TableCell>
                    <Typography
                      component="span"
                      sx={{
                        color: "#8A2BE2",
                        cursor: "pointer",
                        "&:hover": {
                          color: "#808080",
                        },
                        fontSize: 16,
                      }}
                      onClick={() => showApi(index)}
                    >
                      {item.showApi ? "隐藏" : "查看"}
                    </Typography>
                    {" | "}
                    <Typography
                      component="span"
                      sx={{
                        color: "#8A2BE2",
                        cursor: "pointer",
                        "&:hover": {
                          color: "#808080",
                        },
                        fontSize: 16,
                      }}
                      onClick={() => handleOpenConfirm(index)}
                    >
                      删除
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <ConfirmDialog
        open={confirm}
        onClose={handleCloseConfirm}
        title="删除API-Key"
        info="是否确认删除当前选择的API-Key? API-Key被删除后无法恢复"
        confirm={deleteApi}
      />
      <CreateApiKeyDialog open={create} onClose={handleCloseCreate} onSubmit={createApi} />
    </Dialog>
  );
};

ApiKeyDialog.defaultProps = {
  open: false,
  onClose: () => {},
};

ApiKeyDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default ApiKeyDialog;
