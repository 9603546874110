/**

*/
import { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { Box, IconButton, Typography, Card, Button, TextField } from "@mui/material";
import { Grid, CircularProgress } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import HistoryIcon from "@mui/icons-material/History";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import ImageIcon from "@mui/icons-material/Image";
import InfoIcon from "@mui/icons-material/Info";
import SendIcon from "@mui/icons-material/Send";
import KeyIcon from "@mui/icons-material/Key";
import MicIcon from "@mui/icons-material/Mic";
import SelectModelDialog from "examples/ModelDialog";
import brandWhite from "assets/images/apple-icon.png";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Backdrop from "@mui/material/Backdrop";
import MessageBox from "./components/messageBox";

function ModelsTest() {
  const [leftActive, setLeftActive] = useState(false);

  const [dialogs, setDialogs] = useState([]);
  const handleAddDialog = () => {
    let newDialog = {
      id: dialogs.length,
      title: "wtf",
      time: new Date().toLocaleString(),
    };
    setDialogs([...dialogs, newDialog]);
  };
  const handleDeleteDialog = (id) => {
    let newDialogs = [];
    dialogs.forEach((d) => {
      if (d.id < id) {
        newDialogs.push(d);
      }
      if (d.id > id) {
        d.id -= 1;
        newDialogs.push(d);
      }
    });
    setDialogs(newDialogs);
  };

  const [selectedModels, setSelectedModels] = useState(0);
  const [models, setModels] = useState([]);
  const location = useLocation();
  useEffect(() => {
    if (location.state && location.state[0]) {
      setModels([...models, location.state[0]]);
      setSelectedModels(1);
      setSendAllowed(true);
    }
  }, [location.state]);
  const [modelDialogOpen, setModelDialogOpen] = useState(false);
  const handleOpenDialog = () => {
    if (selectedModels < 2) {
      setModelDialogOpen(true);
    }
  };
  const handleCloseDialog = () => {
    setModelDialogOpen(false);
  };
  const handleSelectModel = (model) => {
    let newModel = {
      id: models.length,
      name: model.name,
    };
    setSelectedModels((prev) => (prev < 2 ? prev + 1 : prev));
    setModels([...models, newModel]);
    setSendAllowed(true);
  };
  const handleDeleteModel = (id) => {
    let newModels = [];
    models.forEach((model) => {
      if (model.id < id) {
        newModels.push(model);
      }
      if (model.id > id) {
        model.id -= 1;
        newModels.push(model);
      }
    });
    let newChatHistory = [[], []];
    if (id === 0) {
      newChatHistory[0] = chatHistory[1];
    } else if (id === 1) {
      newChatHistory[0] = chatHistory[0];
    }
    setChatHistory(newChatHistory);
    setSelectedModels((prev) => prev - 1);
    setModels(newModels);
    if (newModels.length === 0) {
      setSendAllowed(false);
    }
  };
  const handleChangeModel = (id) => {
    handleDeleteModel(id);
    setModelDialogOpen(true);
  };

  const [loading, setLoading] = useState([false, false]);
  const [error, setError] = useState(false);
  const [infoLabel, setInfoLabel] = useState("");
  const [infoText, setInfoText] = useState("");
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const handleClose = () => setError(false);

  const [chatText, setChatText] = useState("");
  const handleChatTextChange = (event) => {
    const value = event.target.value;
    setChatText(value);
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      sendChat();
    }
  };
  const [chatHistory, setChatHistory] = useState([[], []]);
  const [sendAllowed, setSendAllowed] = useState(false);
  const sendChat = async () => {
    setSendAllowed(false);
    let d = new Date();
    let timeString = d.toLocaleString();
    let newChat = {
      content: chatText,
      role: "user",
      time: timeString,
    };
    let newChatHistory = [[], []];
    let l = [false, false];
    for (let i = 0; i < selectedModels; i++) {
      newChatHistory[i] = [...chatHistory[i], newChat];
      l[i] = true;
    }
    setLoading(l);
    setChatHistory(newChatHistory);
    setChatText("");
    for (let i = 0; i < selectedModels; i++) {
      try {
        const apiUrl = process.env.REACT_APP_API_URL;
        let response = await axios.post(
          `${apiUrl}/v1/chat/completions`,
          {
            messages: newChatHistory[i],
            model: models[i].name,
            stream: false,
          },
          {
            headers: {
              Authorization: "Bearer sk-nQpY9Cw46m3bbiz2jFY1sFDc5EPbH5RwSMWbsmXSjjyEK1ic",
            },
          }
        );
        if (response.status === 200) {
          let d = new Date();
          let timeString = d.toLocaleString();
          let aiChat = {
            content: response.data.choices[0].message.content,
            role: response.data.choices[0].message.role,
            time: timeString,
          };
          newChatHistory[i] = [...newChatHistory[i], aiChat];
          setChatHistory([...newChatHistory]);
        } else {
          setInfoLabel("模型体验");
          setInfoText("模型对话出错");
          setError(true);
        }
      } catch (error) {
        console.log(error);
        setInfoLabel("模型体验");
        setInfoText("模型对话出错");
        setError(true);
      } finally {
        l[i] = false;
        setLoading([...l]);
        if (!l[0] && !l[1]) {
          setSendAllowed(true);
        }
      }
    }
  };
  const leftSectionRef = useRef(null);
  const rightSectionRef = useRef(null);
  useEffect(() => {
    if (leftSectionRef.current) {
      leftSectionRef.current.scrollTop = leftSectionRef.current.scrollHeight;
    }
  }, [chatHistory[0]]);
  useEffect(() => {
    if (rightSectionRef.current) {
      rightSectionRef.current.scrollTop = rightSectionRef.current.scrollHeight;
    }
  }, [chatHistory[1]]);

  return (
    <DashboardLayout>
      <Box>
        <Box display="flex" alignItems="flex-start" height="95vh" gap={2}>
          {!leftActive ? (
            <Box
              width="10vw"
              height="100%"
              display="flex"
              flexDirection="column"
              sx={{ overflow: "hidden" }}
            >
              <Button
                component="label"
                variant="contained"
                startIcon={<HistoryIcon />}
                onClick={() => setLeftActive(true)}
                sx={{
                  backgroundColor: "#ffffff",
                  color: "#707070",
                  float: "right",
                  fontSize: 15,
                  position: "absolute",
                  left: 0,
                  top: "5%",
                }}
              >
                对话记录
              </Button>
            </Box>
          ) : (
            <Box
              width="10vw"
              height="100%"
              display="flex"
              flexDirection="column"
              sx={{ borderRadius: 1, bgcolor: "#ffffff", overflow: "hidden" }}
            >
              <Box sx={{ position: "relative", textAlign: "center", height: "100%" }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "0 1rem",
                    mt: 2,
                  }}
                >
                  <HistoryIcon />
                  <Typography variant="h6" sx={{ flexGrow: 1, textAlign: "center" }}>
                    对话记录
                  </Typography>
                  <IconButton onClick={() => setLeftActive(false)}>
                    <CloseIcon />
                  </IconButton>
                </Box>
                <Button
                  onClick={handleAddDialog}
                  variant="contained"
                  sx={{
                    backgroundColor: "#650bdb",
                    color: "#ffffff",
                    width: "80%",
                    mt: 2,
                    fontSize: 15,
                    fontWeight: "light",
                  }}
                >
                  创建对话
                </Button>
                <Box sx={{ overflowY: "auto", height: "90%", padding: "1rem" }}>
                  {dialogs.map((dialog) => (
                    <Box
                      key={dialog.id}
                      sx={{
                        marginBottom: "1rem",
                        padding: "1rem",
                        position: "relative",
                        border: "2px solid #cecece",
                        borderRadius: 2,
                        cursor: "pointer",
                        "&:hover": {
                          bgcolor: "#c9c9c9",
                        },
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#000000",
                          fontSize: 16,
                          textAlign: "left",
                          paddingLeft: "1%",
                        }}
                      >
                        {dialog.title}
                      </Typography>
                      <Typography
                        sx={{
                          color: "#868686",
                          fontSize: 14,
                          textAlign: "left",
                          paddingLeft: "1%",
                        }}
                      >
                        {dialog.time}
                      </Typography>
                      <IconButton
                        onClick={() => handleDeleteDialog(dialog.id)}
                        sx={{ position: "absolute", top: "17%", right: "1%" }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>
          )}
          <Box
            width="70vw"
            height="100%"
            display="flex"
            flexDirection="column"
            sx={{ overflow: "hidden" }}
          >
            <Box
              sx={{
                padding: "1rem",
                borderBottom: "1px solid #ddd",
                flexShrink: 0,
              }}
            >
              {selectedModels === 0 ? (
                <Box display="flex" alignItems="center">
                  <Typography flex="1">当前还未选择模型，请点击画面右侧按钮选择模型</Typography>
                </Box>
              ) : selectedModels === 1 ? (
                <Box
                  display="flex"
                  alignItems="center"
                  sx={{
                    borderRadius: "20px",
                    padding: "0.5rem 1rem",
                    bgcolor: "#ffffff",
                  }}
                >
                  <Box
                    component="img"
                    src={brandWhite}
                    sx={{ width: "24px", height: "24px", marginRight: "0.5rem" }}
                  />
                  <Typography flex="1" sx={{ fontWeight: "bold" }}>
                    {models[0].name}
                  </Typography>
                  <Typography
                    sx={{ color: "#650bdb", cursor: "pointer", marginRight: "0.5rem" }}
                    onClick={() => handleChangeModel(0)}
                  >
                    更换模型
                  </Typography>
                  <IconButton>
                    <KeyIcon />
                  </IconButton>
                  <IconButton onClick={() => handleDeleteModel(0)}>
                    <CloseIcon />
                  </IconButton>
                </Box>
              ) : (
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Box
                      display="flex"
                      alignItems="center"
                      sx={{
                        borderRadius: "20px",
                        padding: "0.5rem 1rem",
                        bgcolor: "#ffffff",
                      }}
                    >
                      <Box
                        component="img"
                        src={brandWhite}
                        alt="模型图片"
                        sx={{ width: "24px", height: "24px", marginRight: "0.5rem" }}
                      />
                      <Typography flex="1" sx={{ fontWeight: "bold" }}>
                        {models[0].name}
                      </Typography>
                      <Typography
                        sx={{ color: "#650bdb", cursor: "pointer", marginRight: "0.5rem" }}
                        onClick={() => handleChangeModel(0)}
                      >
                        更换模型
                      </Typography>
                      <IconButton>
                        <KeyIcon />
                      </IconButton>
                      <IconButton onClick={() => handleDeleteModel(0)}>
                        <CloseIcon />
                      </IconButton>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box
                      display="flex"
                      alignItems="center"
                      sx={{
                        borderRadius: "20px",
                        padding: "0.5rem 1rem",
                        bgcolor: "#ffffff",
                      }}
                    >
                      <Box
                        component="img"
                        src={brandWhite}
                        alt="模型图片"
                        sx={{ width: "24px", height: "24px", marginRight: "0.5rem" }}
                      />
                      <Typography flex="1" sx={{ fontWeight: "bold" }}>
                        {models[1].name}
                      </Typography>
                      <Typography
                        sx={{ color: "#650bdb", cursor: "pointer", marginRight: "0.5rem" }}
                        onClick={() => handleChangeModel(1)}
                      >
                        更换模型
                      </Typography>
                      <IconButton>
                        <KeyIcon />
                      </IconButton>
                      <IconButton onClick={() => handleDeleteModel(1)}>
                        <CloseIcon />
                      </IconButton>
                    </Box>
                  </Grid>
                </Grid>
              )}
            </Box>
            <Box sx={{ flex: "1 1 auto", display: "flex", gap: 2, overflow: "hidden" }}>
              {selectedModels >= 1 && (
                <Box
                  sx={{
                    flex: 1,
                    overflowY: "auto",
                    padding: "1rem",
                    display: "flex",
                    flexDirection: "column",
                  }}
                  ref={leftSectionRef}
                >
                  {chatHistory[0].map((message, index) => (
                    <MessageBox key={index} message={message} scrollContainerRef={leftSectionRef} />
                  ))}
                  {loading[0] && (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "1rem",
                      }}
                    >
                      <CircularProgress />
                    </Box>
                  )}
                </Box>
              )}
              {selectedModels === 2 && (
                <Box
                  sx={{
                    flex: 1,
                    overflowY: "auto",
                    padding: "1rem",
                    display: "flex",
                    flexDirection: "column",
                  }}
                  ref={rightSectionRef}
                >
                  {chatHistory[1].map((message, index) => (
                    <MessageBox
                      key={index}
                      message={message}
                      scrollContainerRef={rightSectionRef}
                    />
                  ))}
                  {loading[1] && (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "1rem",
                      }}
                    >
                      <CircularProgress />
                    </Box>
                  )}
                </Box>
              )}
            </Box>
            <Box sx={{ padding: "1rem", flexShrink: 0 }}>
              <Box display="flex" alignItems="center" gap={1} sx={{ mb: 1 }}>
                <IconButton
                  sx={{
                    borderRadius: 2,
                    border: "2px solid #cecece",
                    padding: "0.5rem",
                    bgcolor: "#ffffff",
                    "&:hover": {
                      bgcolor: "#b0b0b0",
                    },
                  }}
                >
                  <MicIcon />
                </IconButton>
                <IconButton
                  sx={{
                    borderRadius: 2,
                    border: "2px solid #cecece",
                    padding: "0.5rem",
                    bgcolor: "#ffffff",
                    "&:hover": {
                      bgcolor: "#b0b0b0",
                    },
                  }}
                >
                  <ImageIcon />
                </IconButton>
                <IconButton
                  sx={{
                    borderRadius: 2,
                    border: "2px solid #cecece",
                    padding: "0.5rem",
                    bgcolor: "#ffffff",
                    "&:hover": {
                      bgcolor: "#b0b0b0",
                    },
                  }}
                >
                  <FileOpenIcon />
                </IconButton>
                <Box display="flex" alignItems="center" justifyContent="center" gap={1} ml={1}>
                  <InfoIcon sx={{ fontSize: "small" }} />
                  <Typography sx={{ fontSize: 14, color: "#858585", mt: 0.5 }}>
                    体验模型将会消耗tokens，费用以实际发生为准
                  </Typography>
                </Box>
              </Box>
              <Box display="flex" position="relative" alignItems="center" sx={{ mb: 1 }}>
                <TextField
                  variant="outlined"
                  placeholder="请输入您的问题"
                  multiline
                  fullWidth
                  value={chatText}
                  onChange={handleChatTextChange}
                  onKeyDown={handleKeyPress}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      padding: "0.5rem",
                      paddingRight: "50px",
                      borderRadius: "20px",
                      bgcolor: "#ffffff",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "transparent",
                    },
                  }}
                />
                <IconButton
                  disabled={!sendAllowed}
                  sx={{
                    position: "absolute",
                    right: "1rem",
                    top: "50%",
                    transform: "translateY(-50%)",
                    color: "#ffffff",
                    bgcolor: "#696969",
                    borderRadius: 2,
                    padding: "0.5rem",
                    "&:hover": {
                      bgcolor: "#b0b0b0",
                    },
                  }}
                  onClick={sendChat}
                >
                  <SendIcon />
                </IconButton>
              </Box>
              <Typography textAlign="center" sx={{ fontSize: 14, color: "#858585" }}>
                对话中的所有回答内容均由人工智能模型生成，不代表公司态度或观点
              </Typography>
            </Box>
          </Box>
          <Box
            width="2vw"
            height="60%"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            onClick={handleOpenDialog}
            sx={{
              borderTopLeftRadius: 8,
              borderBottomLeftRadius: 8,
              bgcolor: "#ffffff",
              overflow: "hidden",
              cursor: "pointer",
              position: "absolute",
              top: "20%",
              right: 0,
              boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.1)",
              padding: "1rem",
            }}
          >
            <Typography variant="h6">+</Typography>
            <Typography variant="body2" mt={1}>
              增加模型
            </Typography>
            <Typography variant="body2" mt={1}>
              ({selectedModels}/2)
            </Typography>
          </Box>
        </Box>
      </Box>
      <SelectModelDialog
        open={modelDialogOpen}
        onClose={handleCloseDialog}
        onConfirm={handleSelectModel}
      />
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={error}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={error}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6">
              {infoLabel}
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
              {infoText}
            </Typography>
          </Box>
        </Fade>
      </Modal>
    </DashboardLayout>
  );
}

export default ModelsTest;
