import React, { useState } from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { okaidia } from "react-syntax-highlighter/dist/esm/styles/prism";
import { CopyToClipboard } from "react-copy-to-clipboard";
import PropTypes from "prop-types";
import { Button } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const CodeBlock = ({ language, code }) => {
  const [copied, setCopied] = useState(false);

  return (
    <div style={{ position: "relative" }}>
      <CopyToClipboard text={code} onCopy={() => setCopied(true)}>
        <Button
          variant="text"
          sx={{
            position: "absolute",
            right: 0,
            top: -30,
            color: "#ffffff",
            "&:hover": {
              color: "#c7c7c7",
            },
            fontSize: 14,
            fontWeight: "regular",
          }}
        >
          {copied ? "复制成功" : "复制代码"}
          <ContentCopyIcon />
        </Button>
      </CopyToClipboard>
      <SyntaxHighlighter language={language} style={okaidia}>
        {code}
      </SyntaxHighlighter>
    </div>
  );
};

CodeBlock.defaultProps = {
  language: "",
  code: "",
};

CodeBlock.propTypes = {
  language: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
};

export default CodeBlock;
