import React from "react";
import { Typography, Dialog, DialogTitle, DialogContent, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";

const UserAgreement = ({ open, onClose }) => {
  const content = `
  星觉大模型平台产品用户协议

  版本生效日期：2024年8月22日
  版本更新日期：2024年8月22日
  欢迎您体验星觉大模型平台产品！
  【审慎阅读】您在同意本协议之前，请务必审慎阅读、充分理解各条款的内容，特别是免除或者限制责任的条款、法律适用和争议解决条款，这些条款将以粗体或粗体下划线标识，您应重点关注。如您对本协议有任何疑问，可以通过本协议披露的联系方式与我们取得联系。
  【签约动作】当您勾选或点击同意本协议或开始使用本产品或以其他方式选择接受本协议后,即表示您已充分阅读、理解并接受本协议的全部内容,并与我们达成一致。本协议自您通过网络页面勾选或点击确认或以其他方式选择接受本协议之日起成立。如果您不同意本协议或其中任何条款约定，请勿进行签约动作。
  
  一、签约主体及协议范围
  本协议是您与上海擎算科技有限公司（或简称“我们”）就您通过星觉系列App、小程序以及随技术发展出现的新形态（或简称“本产品”）使用我们提供的产品或服务签署的服务协议。
  具体而言，本产品包括但不限于星觉大模型平台官网、星觉大模型平台App、星觉大模型平台小程序，但以API、SDK、开源软件包等形式对外的服务形态除外 。但若我们及关联公司向您提供的产品或服务内设有单独用户协议或类似法律文件，该等单独的文件优先适用。
  
  二、账号注册及管理
  2.1.注册的资格
  2.1.1.您确认，在您完成注册程序或以其他我们允许的方式实际使用本产品时，您应当是具备完全民事权利能力和完全民事行为能力的自然人、法人或其他组织（简称“您”）。
  2.1.2.若您为未成年人或限制民事行为能力人,则您不具备前述主体资格,您及您的监护人应承担因您的不当注册行为而导致的一切后果。
  2.1.3.您还需确保您不是任何国家、国际组织或者地域实施的贸易限制、制裁或其他法律、规则限制对象,否则您可能无法正常注册及使用我们的服务。
  2.2.账号注册及登录
  2.2.1.当您按照注册页面提示填写信息、阅读并同意本产品用户协议且完成全部注册程序后，您可获得星觉大模型平台用户账号并正式成为星觉大模型平台产品的用户，并会为您自动生成一个“账号ID”。您理解并同意，当提及您的星觉大模型平台用户账号时，实际是指账号名称与登录手机号或在用户界面内归属在“账号”下的其他信息的统称。
  2.2.2.您设置的账号名不得违反国家法律法规、公序良俗、社会公德、我们的管理规范或容易引起您与我们身份的混淆,否则您的账号可能不能注册成功或我们有权经通知您后主动子以注销。
  2.2.3.您应当按照法律法规要求或按相应页面的提示，准确提供并及时更新您的账号信息，以使之真实、及时、完整和准确。如您提供的资料错误、不实、过时或不完整的，我们可以向您发出询问及/或要求改正的通知，您应按照我们的要求配合提供或者更新相关资料。因您填写的信息、资料不真实、不及时、不完整或不准确的,您应承担您不能使用星觉大模型平台用户账号(不能注册成功、或账号被冻结、注销)或您在使用过程中的后果和损失。
  2.2.4.通常情况下,您的账号是您在本产品内进行一切活动的唯一身份标识,除非另有约定,每一个账
  号都可以在本产品内独立开展活动。但在下列情形下，我们有权根据自己的判断，对同一及/或关联法律主体拥有的多个星觉大模型平台用户账号进行统一处理,如根据不同星觉大模型平台用户账号在注册、登录、使用中的关联信息,我们判断其实际为同一用户。关联信息举例:同一证件、同一手机号、同一支付账号、同一设备、同一地址等,。
  2.3.账号的使用和安全
  2.3.1.您有权使用您设定的账号登录并使用本产品。您知恶并同意,我们有权不时变更、暂停或取消任何一项服务(包括产品种类增加或减少、服务内容的调整、版本升级等),或不时变更、暂停或取消服务的某种特性或功能,或者不时变更、暂停或消服务的使用方式及有关服务内容。我们可能会通过本产品公告、站内信或您的登录手机号码、电子邮箱地址等将该等变更、暂停或取消信息通知到您。您有义务及时查收并透彻理解相关调整内容,如您不同意变更后的任何条款,请勿继续使用相关服务。如您使用我们提供的相关服务,即视为您同意遵守届时公示的服务条款。
  2.3.2.一个星觉大模型平台用户账号仅能对应唯一的法律主体。除非有法律明文规定、司法裁定或者经我们同意的情况下,您不得以任何方式转让、转借、租售、赠与或让他人继承您的星觉大模型平台用户账号。
  2.3.3.您的账号信息由您自行设置并由您保管，您须对您的账号和密码、手机验证码及其他与账号相关的信息、资料予以保密。您需确保您在每个上网时段结束时，以正确步骤离开网站。
  2.3.4.如您发现他人未经授权使用您的星觉大模型平台用户账号,您应立即通知我们;我们将协助您冻结账号、更改密码或进行其他安全设置;您理解我们对您的请求采取行动需要合理时间,我们对在采取行动前已经产生的以及由您引发的后果(包括但不限于您的任何损失)不承担任何责任。
  2.3.5.我们会采用业内通行的安全保护措施以保障您的账号安全。
  
  三、账号的冻结、注销及申诉
  3.1.账号的冻结
  您的星觉大模型平台用户账号（全部或部分权限或功能）在如下情况可能被冻结，届时我们将可能通过邮件、站内信、短信或电话等方式通知您：
  3.1.1.您违反本协议、本产品页面展示的合规说明文案、以及其他由我们事先向您说明的产品使用要求的；
  3.1.2.您遭到他人投诉，且对方已经提供了相关证据的，而您未按照我们的要求提供相反证据的；3.1.3.我们根据合理分析判断您的账号操作、收益、兑换等存在异常的；
  3.1.4.您违反国家法律、法规、政策、法律文书的规定的；
  3.1.5.监管单位明确要求我们对您的账号进行冻结的；
  3.1.6.我们基于合理判断，同一用户发生与如上行为性质相同或产生如上类似风险的其他情况的。
  3.2.账号注销
  3.2.1.出现以下情形之一的，您的星觉大模型平台用户账号将可能会被注销：
  （1）我们依据您本人提交的账号注销申请，依照法律的规定和账号注销流程，处理您提交的注销请求并完成账号注销；
  （2）当出现如上第3.1条情形且情形严重的，或基于监管单位的要求，我们对您的相关星觉大模型平台用户账号进行注销，我们将通过邮件、短信、电话或其他适宜的方式通知您；
  （3）当您连续12个月未通过账号登录过星觉大模型平台产品（含账号冻结期间），我们将有权（但无义务）注销您的账号。
  3.2.2.您理解并同意，账号一经注销，您将：
  
  （1）无法再次登录星觉大模型平台网站、App、小程序等；
  （2）无法找回该账号下的个人信息、交易记录、业务数据、历史信息等；
  （3）无法进行依赖于账号权限进行的其他操作。
  3.2.3.账号注销后，我们将：
  （1）无权继续收集、存储和使用您的个人信息和数据，但法律法规另有要求的情形除外；
  （2）有义务在遵守国家法律法规的前提下，保障您在该账号下的所有数据在实现日常业务功能所涉及的系统中不可被检索、访问；
  （3）账号注销不影响我们在监管机关要求或其他合法场景下，如依法确认该账号注销前的用户真实身份等相关义务的履行。
  3.2.4.您知悉并理解账号注销并不代表您在该账号注销前的所有账号行为和相关责任得到豁免或减轻。
  3.3.申诉
  发生前述账号冻结或注销情况下，您应及时予以关注并可以按照程序进行申诉等后续操作：
  3.3.1.您通过申诉程序，向我们申请解除上述冻结或注销的，为了您的账号安全，您应配合如实提供身份证明及相关资料，以及我们要求的其他信息或文件，以便进行核实。您应充分理解您的申诉并不必然被允许，我们有权决定是否同意您的申诉请求。
  3.3.2.您理解并同意，如果您拒绝如实提供身份证明及相关资料，或未能通过我们的审核，我们有权长期冻结该等账号且长期限制账号部分或全部功能，直至前述情形被得以合理纠正，或基于第3.2条的约定注销该账号。
  3.4.您理解并同意，您名下如存在多个星觉大模型平台用户账号，因您的违法、违规、违约行为导致其中一个或多个账号被冻结或注销的，我们有权根据具体情形及相关风险程度作合理判断，对您的其他全部或部分账号予以冻结或注销。
  
  四、服务规范
  4.1.针对您使用本产品制作的信息内容，我们依法提出如下要求：
  4.1.1.此处所述制作的信息内容是指您使用本产品过程中所上传、复制、生成、存储、发布、传播的所有内容，包括但不限于账号头像、名称等账号信息以及您输入、回复、生成的文字、语音、图片、视频等信息内容。
  4.1.2.您不得利用本产品自行或者帮助他人上传、诱导生成、传播以下信息内容：
  （1）违反国家规定的政治宣传和新闻信息；
  （2）涉及国家安全和国防利益的信息；
  （3）封建迷信和淫秽、色情信息；
  （4）涉及博彩有奖、赌博游戏、“私服”、“外挂”等非法互联网活动的相关信息；
  （5）违反国家民族和宗教政策的信息；
  （6）妨碍互联网运行安全的信息；
  （7）侵害他人合法权益的信息和其他有损于社会秩序、公共道德的信息；
  （8）其他违反法律、法规、部门规章或国家政策的信息。
  4.1.3.您同意我们有权采取技术或者人工方式对您的输入数据和合成结果进行审核,有权在无需通知您的情形下删除我们或有权的监管机构认为违反了任何法律或平台协议或政策的内容(简称“违规内容”),但该等审核并不代表我们需承担额外
  的责任,您注册账号下的一切行为由您自行完全负责。如您在本产品内上载、传播上述违规内容⽽导致任何第三⽅提出索赔要求或衍⽣的任何损害或损失，由您⾃⾏承担全部责任。
  4.1.4.您确保，您使用本产品制作的信息内容，是您所有或被相关权利人授权同意处理的信息，我们根据您的指令分析、处理该等信息内容以及生成新的信息内容不会侵犯任何第三方的合法权益（包括但不限于人格权、著作权、财产权）。
  4.1.5.您不得利用本产品编造、传播虚假信息。我们会通过技术手段对本产品下的合成内容予以标识,您不得通过裁剪或其他技术手段移除标识,同时您应通过以其他显著方式对合成内容予以注明(包括但不限于在生成图片中添加水印、附上非真实信息声明)。如您非法利用本产品编造、传播虚假信息的,我们会依法保存有关记录,并向网信部门和有关主管部门报告。
  4.2.您确保不得通过任何方式攻击本产品，不得修改、改编、翻译本产品所使用的软件、技术、材料等，不得通过反向工程、反编译、反汇编或其他类似行为获得本产品涉及的源代码，否则由此引起的一切法律后果由您负责，我们有权依法追究您的法律责任。
  4.3.您知悉并同意本产品仅限您用于个人学习、研究、体验等目的使用，未经我们同意，您不得基于本产品进一步对外提供服务。
  4.4.本产品中可能包含网页阅读服务,您知恶并同意您需在合法合规的范围使用该服务,包括但不限于遵守目标网站的管理规范及知识产权、不解析含有违规内容的网站等,我们作为工具提供方不对您的使用行为承担责任,您需审慎判断风险并独立承担责任 
  4.5.您使用本产品下的各项功能时,须了解和遵循由我们在产品服务界面、产品文档内明确的使用规则;若由于您违反使用规则或未了解使用规则,以致产生权益损害的,须由您自行承担。
  
  五、知识产权
  5.1.本产品的商标、服务标记、URL、文字、服务名称以及它们的组合（统称“标识”）归我们或我们的关联公司所有，受到知识产权法律的保护。未经我们或我们的关联公司的许可，您不得将本条款前述标识以单独或结合任何方式展示、使用或申请注册商标、进行域名注册等，也不得实施向他人明示或暗示有权展示、使用、或其他有权处理该些标识的行为。
  5.2.双方各自在本协议签订前亨享有的知识产权,仍归各方所有;并不会因为双方签订或者履行本协议而转归对方享有,或者转归双方共同享有。
  5.3.您应确保在使用本产品过程中，上传的任何资料、素材等的知识产权均归您所有或您已获得合法权利人授权，前述内容及您的使用行为不存在任何现实的或潜在的争议。如果第三方机构或个人对您使用我们服务所涉及的相关素材的知识产权归属提出质疑或投诉，或对您使用的我们服务的知识产权的归属提出质疑或投诉，您和我们均有责任出具相关知识产权证明材料，并配合对方的相关投诉处理工作。对于因此引起的索赔、诉讼或可能向其提起诉讼，违约方应负责解决，承担费用和损失，以及使另一方免责
  5.4.除非另有约定或法律法规另有规定的，若您对于您上传内容具有合法知识产权的、合成内容的知识产权仍归属于您：但前述合成内容是香具有知识产权、以及知识产权的归属，应由您自行判断及处理，我们对由此造成的任何损失不承担责任；由此给我们或其他XX关联公司造成损失的，您应当承担赔偿责任。为免疑问，若您上传及/或生成的内容中包含了我们享有的知识产权或其他合法权益成分，则无论这些成分在合成过程中如何整合，相关权利始终由我们持有，并不会因内容被合成而转移其原有权属。
  5.5.您确认并同意，通过本产品及相关配套服务上传、生成、发布或传播的所有信息内容，您特此赋予我们和/或关联公司一项全球范围、永久有效、免费的许可权，允许我们在优化模型和服务的过程中使用您的信息内容。该许可不仅包括直接使用权，还涵盖可转让性、以及向第三方分许可或再许可的权利。此外，对于您经由本服务及关联服务输入并传播的任何部分信息内容，您明确授权我们和/或关联方有权使用这些内容，以用于与我们品牌相关的市场营销、推广活动、学术研究及其他相关用途。
  
  六、个人信息保护
  6.1.我们深知个人信息对您的重要性，您的信赖对我们非常重要，我们将严格遵守法律法规要求采取相应的安全保护措施，致力于保护您的个人信息。
  6.2.您在参与本产品的过程中我们可能会要求您向我们提供、同时我们也可能会主动收您的一些必要个人信息。你可通过《星觉大模型平台产品隐私政策》详细了解我们如何收集、使用、保护您的个人信息。
  
  七、责任限制与违约责任
  7.1.因现阶段科学技术的局限性和生成式人工智能的特殊性，我们特别提醒您，我们不能保证本产品生成内容合规、准确和完整，所生成内容也不能代表我们的态度和观点。请不要对本产品生成的所有信息内容产生任何依赖，我们将不对您因对本平台的依赖而造成的任何损害负责。
  7.2.我们不承担因您违反本协议而引发的任何法律责任和风险。当您违约使用本产品侵犯了第三方的合法权益而导致我们或/及我们的合作方遭受任何投诉、举报、索赔或诉讼，您有义务积极采取措施(包括但不限于积极举证回应、参与诉讼)，以保证我们和我们的合作方免受或尽快摆脱该困境。当我们或/及我们的合作方因此遭受任何名誉、声誉或财产上的直接或间接损失时，您负有全部的损害赔偿责任。
  7.3.您违反上述约定时，我们有权就具体情节，对您做出警告、限制功能、暂停使用、封禁账号甚至关闭账号的处罚，对于危害国家安全、公共安全和国防利益的行为，我们将进行严厉处理；如因您违反上述规范而致使其他用户、我们或我们的任何合作方权益受损，您需自行完全承担一切法律责任，严重时您将可能承担刑事责任。
  
  八、投诉、申诉处理
  8.1.投诉。若您觉得本产品可能侵犯了您的知识产权或其他合法权益，或者您在使用本产品过程中遇到了任何非法、虚假的内容，以及违反服务协议的行为，您可以通过本协议披露的联系方式与我们取得联系并进行反馈，为便于我们进行核查、确认，我们可能会要求您提供您的真是身份信息、权属证明材料等。我们会在接到您的反馈后，在合理期限内与您取得联系、核实相关情况，并在情况核实后采取处置措施。
  8.2.申诉。如果您认为我们不恰当地对您使用本产品的行为作出限制性决定的，您可以向我们提起申诉，您可以将相关申诉材料发送至本协议披露的电子邮箱地址；申诉所需的材料至少包括您的账号信息、身份证或护照复印件（自然人用户）、单位登记证明复印件（法人用户）、账号当前状态、账号因何种使用情形被采取限制性措施以及您希望我们采取何种措施恢复您使用本产品的能力。我们会在接到您的申诉材料后，在合理期限内与您取得联系、核实相关情况，并在情况核实后采取相关措施。
  
  九、法律适用及争议解决
  9.1.本协议之订立、生效、解释、修订、补充、终止、执行与争议解决均适用中华人民共和国内地的法律。
  9.2.您因使用本产品所产生及与本产品有关的争议，由我们与您协商解决。协商不成时，任何一方均可向上海市闵行区人民法院提起诉讼。
  
  十、其他
  10.1.本协议由本协议的内容、应用相关页面上展现的规则、规范，服务说明（含操作文档）和您点击确认的其他条款/条件组成，我们和您均受其约束，且其中的相关名词可相互引用、解释。
  10.2.我们有权对本协议及相应的服务规则内容进行变更，并将网页公告、电子邮件、站内信、短信等方式予以公告或通知；若您在本协议内容变更后继续使用本产品的，表示您已充分阅读、理解并接受修改后的内容，也将遵循修改后的条款内容。
  10.3.如您针对本协议、星觉系列服务有任何问题，可以通过如下联系方式与我们取得联系：
  10.3.1.「星觉大模型平台官网」、「星觉大模型平台App」、「星觉大模型平台小程序」及其同系列产品的相关问题，可发送邮件至 shqs_business@xinglin-group.com或在产品页面展示的"投诉与反馈”入口进行反馈。
  10.3.2.「星觉大模型平台官网」的相关问题，可发送邮件至 shqs_business@xinglin-group.com或在产品页面展示的"投诉与反馈”入口进行反馈。
     
  `;

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle>
        用户协议
        <IconButton
          onClick={onClose}
          aria-label="close"
          sx={{ position: "absolute", right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Typography variant="body2" sx={{ whiteSpace: "pre-wrap" }}>
          {content}
        </Typography>
      </DialogContent>
    </Dialog>
  );
};

UserAgreement.defaultProps = {
  open: false,
  onClose: () => {},
};

UserAgreement.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default UserAgreement;
