/**

*/
import PropTypes from "prop-types";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";
import { Typography, Box } from "@mui/material";

function Footer({ light }) {
  return (
    <Box
      position="absolute"
      width="100%"
      bottom={0}
      py={4}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Container>
        <Box
          width="100%"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Box
            component="ul"
            sx={({ breakpoints }) => ({
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              justifyContent: "center",
              listStyle: "none",
              mt: 3,
              mb: 2,
              p: 0,

              [breakpoints.up("lg")]: {
                mt: 0,
              },
            })}
          >
            <Box component="li" pr={4} lineHeight={1}>
              <Link href="https://xinglin-group.com/" target="_blank">
                <Typography variant="button" fontWeight="regular" color={light ? "white" : "dark"}>
                  关于我们
                </Typography>
              </Link>
            </Box>
            <Box component="li" px={4} lineHeight={1}>
              <Link href="https://xinglin-group.com/" target="_blank">
                <Typography variant="button" fontWeight="regular" color={light ? "white" : "dark"}>
                  法律声明及隐私政策
                </Typography>
              </Link>
            </Box>
            <Box component="li" px={4} lineHeight={1}>
              <Link href="https://xinglin-group.com/" target="_blank">
                <Typography variant="button" fontWeight="regular" color={light ? "white" : "dark"}>
                  联系我们
                </Typography>
              </Link>
            </Box>
            <Box component="li" pl={4} lineHeight={1}>
              <Link href="https://xinglin-group.com/" target="_blank">
                <Typography variant="button" fontWeight="regular" color={light ? "white" : "dark"}>
                  加入星临科技
                </Typography>
              </Link>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

Footer.defaultProps = {
  light: false,
};

Footer.propTypes = {
  light: PropTypes.bool,
};

export default Footer;
