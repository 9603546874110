import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Box,
  Button,
  Typography,
  Divider,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { CopyToClipboard } from "react-copy-to-clipboard";

const ModelDetail = ({ open, onClose, model, onTry, onSample }) => {
  if (!open || !model) {
    return null;
  }

  const handleOpenSample = () => {
    onClose();
    onSample(model);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle bgcolor="#f3f3f3">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6" ml={2}>
            {model.name}
          </Typography>
          <IconButton edge="end" color="inherit" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        <Box mb={2}>
          <Typography variant="body1" sx={{ fontWeight: 500 }}>
            模型简介
          </Typography>
          <Typography variant="body2" mt={1} color="#000000">
            {model.description}
          </Typography>
        </Box>
        <Divider />
        <Box mb={2}>
          <Typography variant="body1" sx={{ fontWeight: 500 }}>
            模型名称
          </Typography>
          <Box display="flex" alignItems="center" mt={1}>
            <Typography variant="body2" mr={2} color="#000000">
              {model.name}
            </Typography>
            <CopyToClipboard text={model.name}>
              <ContentCopyIcon fontSize="small" sx={{ cursor: "pointer" }} />
            </CopyToClipboard>
          </Box>
        </Box>
        <Divider />
        <Box my={2}>
          <Typography variant="body1" sx={{ fontWeight: 500 }}>
            计费详情
          </Typography>
          <Typography variant="body2" mt={1} color="#000000">
            待填入
          </Typography>
        </Box>
        <Divider />
        <Box my={2}>
          <Typography variant="body1" sx={{ fontWeight: 500 }}>
            免费额度
          </Typography>
          <Typography variant="body2" mt={1} color="#000000">
            待填入
          </Typography>
        </Box>
      </DialogContent>
      <Box display="flex" justifyContent="flex-end" p={2} bgcolor="#f3f3f3">
        <Button
          variant="outlined"
          sx={{
            mr: 2,
            color: "#000000",
            fontSize: 14,
            fontWeight: "regular",
            borderColor: "#4d4d4d",
          }}
          onClick={() => onTry(model)}
        >
          体验
        </Button>
        <Button
          variant="outlined"
          sx={{
            mr: 2,
            color: "#000000",
            fontSize: 14,
            fontWeight: "regular",
            borderColor: "#4d4d4d",
          }}
          onClick={handleOpenSample}
        >
          API示例
        </Button>
        <Button
          variant="outlined"
          sx={{
            mr: 2,
            color: "#000000",
            fontSize: 14,
            fontWeight: "regular",
            borderColor: "#4d4d4d",
          }}
        >
          创建应用
        </Button>
      </Box>
    </Dialog>
  );
};

ModelDetail.defaultProps = {
  open: false,
  onClose: () => {},
  model: {},
  onTry: () => {},
  onSample: () => {},
};

ModelDetail.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  model: PropTypes.object,
  onTry: PropTypes.func.isRequired,
  onSample: PropTypes.func.isRequired,
};

export default ModelDetail;
