import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  OutlinedInput,
} from "@mui/material";

const CreateApiKeyDialog = ({ open, onClose, onSubmit }) => {
  const [selectedApp, setSelectedApp] = useState("AI狼人杀项目");
  const [description, setDescription] = useState("");

  const handleAppChange = (event) => {
    setSelectedApp(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    if (event.target.value.length <= 10) {
      setDescription(event.target.value);
    }
  };

  const handleSubmit = () => {
    if (selectedApp && description) {
      onSubmit(selectedApp, description);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>创建新的API-KEY</DialogTitle>
      <DialogContent>
        <FormControl fullWidth margin="normal">
          <InputLabel id="app-select-label">归属应用</InputLabel>
          <Select
            labelId="app-select-label"
            value={selectedApp}
            onChange={handleAppChange}
            sx={{ height: "5vh" }}
            input={<OutlinedInput label="归属应用" />}
          >
            <MenuItem value="AI狼人杀项目">AI狼人杀项目</MenuItem>
            <MenuItem value="项目2">项目2</MenuItem>
            <MenuItem value="项目3">项目3</MenuItem>
          </Select>
        </FormControl>
        <TextField
          label="描述"
          multiline
          fullWidth
          margin="normal"
          value={description}
          onChange={handleDescriptionChange}
          placeholder="请输入描述(最长10个字)"
          sx={{ flexGrow: 1, height: "5vh" }}
          size="small"
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          variant="outlined"
          sx={{
            backgroundColor: "#ffffff",
            color: "#000000",
            float: "right",
            borderColor: "#6e6e6e",
          }}
        >
          取消
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          sx={{
            backgroundColor: "#650bdb",
            color: "#ffffff",
            float: "right",
          }}
        >
          确定
        </Button>
      </DialogActions>
    </Dialog>
  );
};

CreateApiKeyDialog.defaultProps = {
  open: false,
  onClose: () => {},
  onSubmit: () => {},
};

CreateApiKeyDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default CreateApiKeyDialog;
