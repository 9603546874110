/**

*/

import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import { useMaterialUIController, setLayout, setUser } from "context";
import axios from "axios";

function DashboardLayout({ children }) {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, user, appid } = controller;
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const autoLogin = async () => {
    if (!localStorage.getItem("maasAuthData")) {
      navigate("/authentication/sign-in");
      return;
    }
    const authData = JSON.parse(localStorage.getItem("maasAuthData"));
    try {
      const url = process.env.REACT_APP_PASS_URL;
      let response = await axios.get(`${url}/auth/api/auto-login`, {
        headers: { Authorization: `${authData.token}` },
      });
      if (response.status === 200) {
        let loggedUser = {
          name: authData.phone,
        };
        setUser(dispatch, loggedUser);
      } else {
        console.log("自动登录未成功");
        localStorage.clear();
        navigate("/authentication/sign-in");
        return;
      }
    } catch (error) {
      console.log(error);
      localStorage.clear();
      navigate("/authentication/sign-in");
      return;
    }
  };

  useEffect(() => {
    setLayout(dispatch, "dashboard");
    autoLogin();
  }, [pathname]);

  return (
    <Box
      sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
        p: 3,
        position: "relative",

        [breakpoints.up("xl")]: {
          marginLeft: miniSidenav ? pxToRem(50) : pxToRem(250),
          transition: transitions.create(["margin-left", "margin-right"], {
            easing: transitions.easing.easeInOut,
            duration: transitions.duration.standard,
          }),
        },
      })}
    >
      {children}
    </Box>
  );
}

DashboardLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DashboardLayout;
