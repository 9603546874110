import React from "react";
import { Box, Typography } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import PropTypes from "prop-types";
import TypingEffect from "components/typing";

const MessageBox = ({ message, scrollContainerRef }) => {
  const isUser = message.role === "user";
  const backgroundColor = isUser ? "#c8c8ee" : "#ffffff";

  return (
    <Box sx={{ display: "flex", flexDirection: "column", mb: 2 }}>
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        {isUser ? <AccountCircleIcon fontSize="large" /> : <SmartToyIcon fontSize="large" />}
        <Typography color="textSecondary" sx={{ ml: 2, fontSize: 14 }}>
          {message.time}
        </Typography>
      </Box>
      <Box
        sx={{
          mt: 1,
          backgroundColor: backgroundColor,
          padding: "10px",
          borderRadius: "10px",
          maxWidth: "100%",
          wordBreak: "break-word",
          position: "relative",
        }}
      >
        {isUser ? (
          <Typography sx={{ fontSize: 18, mx: 1, my: 1 }}>{message.content}</Typography>
        ) : (
          <TypingEffect text={message.content} speed={20} scrollContainerRef={scrollContainerRef} />
        )}
        {!isUser && (
          <Typography
            sx={{
              fontSize: 10,
              color: "gray",
              position: "absolute",
              bottom: 2,
              right: 10,
            }}
          >
            由AI生成, 仅供参考
          </Typography>
        )}
      </Box>
    </Box>
  );
};

MessageBox.defaultProps = {
  message: {},
  scrollContainerRef: {},
};

MessageBox.propTypes = {
  message: PropTypes.object.isRequired,
  scrollContainerRef: PropTypes.object.isRequired,
};

export default MessageBox;
