import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  FormControl,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";

const ReportIssue = ({ open, onClose }) => {
  const [description, setDescription] = useState("");
  const [selectedIssueType, setSelectedIssueType] = useState("");

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const handleIssueTypeChange = (event, newIssueType) => {
    if (newIssueType !== null) {
      setSelectedIssueType(newIssueType);
    }
  };

  const handleSubmit = () => {
    if (selectedIssueType && description) {
      const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      let result = "";
      const charactersLength = characters.length;
      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * charactersLength);
        result += characters.charAt(randomIndex);
      }
      let d = new Date();
      let n = d.toLocaleString();
      let text = `
        【投诉与反馈ID: ${result}】
        【投诉与反馈类别: ${selectedIssueType}】
        【投诉与反馈内容: ${description}】
        【投诉与反馈时间: ${n}】
      `;
      console.log(text);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>投诉与反馈</DialogTitle>
      <DialogContent>
        <FormControl fullWidth margin="normal">
          <ToggleButtonGroup
            value={selectedIssueType}
            exclusive
            onChange={handleIssueTypeChange}
            aria-label="issue type"
            fullWidth
          >
            <ToggleButton value="用户体验问题" aria-label="用户体验问题">
              用户体验问题
            </ToggleButton>
            <ToggleButton value="模型问题" aria-label="模型问题">
              模型问题
            </ToggleButton>
            <ToggleButton value="其他问题" aria-label="其他问题">
              其他问题
            </ToggleButton>
          </ToggleButtonGroup>
        </FormControl>
        <TextField
          label="描述"
          multiline
          fullWidth
          margin="normal"
          value={description}
          onChange={handleDescriptionChange}
          placeholder="请输入描述投诉内容"
          sx={{ flexGrow: 1 }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          variant="outlined"
          sx={{
            backgroundColor: "#ffffff",
            color: "#000000",
            float: "right",
            borderColor: "#6e6e6e",
          }}
        >
          取消
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          sx={{
            backgroundColor: "#650bdb",
            color: "#ffffff",
            float: "right",
          }}
        >
          确定
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ReportIssue.defaultProps = {
  open: false,
  onClose: () => {},
};

ReportIssue.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ReportIssue;
